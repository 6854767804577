export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 100
  },
  {
    title: '投诉内容',
    key: 'comments',
    minWidth: 180
  },
  {
    title: '投诉人',
    key: 'nickname',
    minWidth: 100
  },
  {
    title: '联系方式',
    key: 'mobile',
    // width: 140,
    minWidth: 100
  },
  {
    title: '投诉时间',
    key: 'create_time',
    minWidth: 100
  },
  {
    title: '投诉类型',
    key: 'type_msg'
    // width: 100
  },
  {
    title: '状态',
    key: 'status',
    // width: 100,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    key: 'actions',
    width: 120,
    extraType: 'button',
    scopedSlots: { customRender: 'actions' }
  }
];
