import request from '@/utils/request';

export function GetComplaint(params) {
  return request({
    url: '/opinion/data',
    method: 'get',
    params
  });
}

export function editComplaint(params) {
  return request({
    url: '/opinion/edit_form',
    method: 'get',
    params
  });
}
